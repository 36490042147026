import { useState } from "react";
import Logo from "../assets/logo.svg";
import Scratch from "../assets/scratch-icon.svg";
import GiftBox from "../assets/giftbox.svg";
import { useNavigate } from "react-router-dom";
import TermsAndCondition from "./TermsAndCondition";

const SplashScreen = () => {
  const navigate = useNavigate();
  const [viewTerms, setViewTerms] = useState(false);
  return (
    // bg-gradient-to-b from-[#2a1b4b] to-[#1d153a]
    <div
      className="full-screen-div 
    
    custom-bg
    px-12 pt-10 pb-2 w-full flex flex-col items-center justify-between full-width overflow-y-scroll"
    >
      <div className="h-full w-full sm:w-80 flex flex-col items-center justify-between full-width">
        <div className="flex items-center justify-center">
          <img className="text-center" src={Logo} alt="Logo" />
        </div>
        <div className="flex items-center justify-center relative">
          <p className="text-[#3fc6f0] text-[20px] text-center">
            Hey!​ Ready to launch your IT career?
          </p>
        </div>
        <div className="flex items-center justify-center">
          <img className="text-center" src={GiftBox} alt="GiftBox" />
        </div>
        <div className="flex items-center justify-center">
          <p className="text-[#fff] text-[16px] text-center">
            Let’s ProSEED to prosper
          </p>
        </div>
        <div className="flex items-center w-full justify-center relative">
          <button
            onClick={() => {
              navigate("/register");
            }}
            className="bg-[#faa219] rounded-3xl text-[#1e1045]  justify-center px-6 py-3 text-center w-full h-[50px] flex items-center text-bold text-2xl"
          >
            <span>Get Started</span>
          </button>
        </div>
        <div className="p-2 rounded-[8px] mx-[-0.75rem] flex text-[#EEE8FA] font-normal font-['lato'] text-sm leading-5 z-10 bg-[#1d153a]">
          <p className="">*&nbsp;</p>
          <p className="">
            Terms and conditions apply.
            <button
              onClick={() => setViewTerms(true)}
              style={{ textDecoration: "underline", fontStyle: "italic" }}
            >
              View more
            </button>
          </p>
        </div>
      </div>
      {viewTerms && (
        <TermsAndCondition onCloseHandler={() => setViewTerms(false)} />
      )}
    </div>
  );
};

export default SplashScreen;
