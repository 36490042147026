import * as React from "react";
const CircleIcon = ({ children, ...props }) => {
  const { variant = "md", stroke = "white", ...rest } = props;
  const iconVariant = {
    lg: {
      circle: {
        cx: "9.5",
        cy: "9.5",
        r: "8.5",
        stroke: stroke,
        strokeWidth: "2",
      },
      svg: {
        width: "19",
        height: "19",
        viewBox: "0 0 19 19",
      },
    },
    md: {
      circle: {
        cx: "8",
        cy: "8",
        r: "8",
      },
      svg: {
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
      },
    },
    sm: {
      circle: {
        cx: "3.5",
        cy: "3.5",
        r: "3.5",
      },
      svg: {
        width: "7",
        height: "7",
        viewBox: "0 0 7 7",
      },
    },
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...iconVariant[variant].svg}
      {...rest}
    >
      <circle {...iconVariant[variant].circle} />
    </svg>
  );
};
export default CircleIcon;
