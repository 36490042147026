import Backround from "../assets/bg-img2.png";
import Logo from "../assets/logo.svg";

const BestOfLuck = () => {
  const backGround = {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center bottom",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "4vh 0",
    backgroundSize: "100% 68%, 100% 100%",
    alignItems: "center",
  };
  return (
    <div style={backGround} className="full-screen-div overflow-y-scroll">
      <div className="text-[#fff] flex flex-col  text-center  ">
        <h1 className="text-3xl mb-4 font-bold pt-4">Best of luck</h1>
        <h5 className="font-normal mb-4 text-2xl w-[227px]">
          Here is your chance to be a winner again !
        </h5>
        <p className="text-lg mb-4 w-[218px]">
          Join us for a special lot with exciting prizes!
        </p>
      </div>

      <div className="flex flex-col items-center  justify-center ">
        <div
          style={{ width: "233px", borderRadius: "20px" }}
          className="p-8 bg-white flex flex-col py-6 justify-around my-4"
        >
          <div className="flex flex-col pb-4 w-full items-center">
            <p className="text-lg">Venue</p>
            <p className="text-lg text-[#510DBC]">Sahya Food court</p>
          </div>
          <div className="flex flex-col pb-4  w-full items-center">
            <p className="text-lg">Date</p>
            <p className="text-lg text-[#510DBC]">10/01/2024</p>
          </div>
          <div className="flex flex-col  w-full items-center">
            <p className="text-lg">Time</p>
            <p className="text-lg text-[#510DBC]">4 PM</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 pb-2"></div>
      <div className="flex z-10 flex-col justify-center w-full items-center gap-5">
        <div className="flex items-center justify-center">
          <img className="text-center" src={Logo} alt="Logo" />
        </div>
      </div>
    </div>
  );
};

export default BestOfLuck;
