import React from "react";

const TermsAndCondition = ({ onCloseHandler }) => {
  return (
    <div
      style={{
        width: "100%",
        position: "fixed",
        background: "rgba(0, 0, 0, .4)",
        top: "0",
        zIndex: "999",
      }}
      className="terms-container full-screen-div flex items-center"
    >
      <div
        style={{ maxHeight: "90%" }}
        className="terms-content-area bg-white w-4/5 gap-4 pt-8 pb-4  overflow-hidden  ps-4 m-auto rounded-3xl flex flex-col"
      >
        <div className="close-btn flex justify-end pe-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            className="cursor-pointer"
            onClick={onCloseHandler}
          >
            <path
              d="M7.99997 6.22224L14.2222 0L16 1.77777L9.77773 8L16 14.2221L14.2222 15.9999L7.99997 9.77775L1.77778 15.9999L0 14.2221L6.22222 8L0 1.77777L1.77778 0L7.99997 6.22224Z"
              fill="#B2B2B2"
            />
          </svg>
        </div>
        <h5 className="text-lg leading-6">Terms and Conditions</h5>
        <div className="ps-5 pe-2 overflow-y-auto text-sm">
          <ul className="list-disc">
            <li>The contest is open for candidate above 18+ of age.</li>
            <li>
              The candidate must have completed senior secondary education or
              class 12.
            </li>
            <li>
              The candidate must enroll in a course offered by ProSEED to avail
              the scholarship.
            </li>
            <li>
              Participation is voluntary and limited to one entry per eligible
              candidate.
            </li>
            <li>
              Scholarship course fee waiver vary and are specified on the
              scratch card.
            </li>
            <li>
              The organizers hold the authority to alter, suspend, or terminate
              the contest at their discretion without prior notice.
            </li>
            <li>
              For any inquiries or concerns related to the contest, please
              contact -
              <a
                className=" mr-[4px] text-[]"
                href="mailto:proseed@frestonanalytics.com"
              >
                proseed@frestonanalytics.com
              </a>
              or{" "}
              <a className=" mr-[4px]" href="tel:+917736458999">
                +91-7736458999
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
