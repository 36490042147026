import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDebouncedCallback } from "use-debounce";

import Backround from "../assets/bg-img.png";
import LogoLinear from "../assets/logo-linear.svg";
import service from "../utils/service";
import TermsAndCondition from "./TermsAndCondition";

const RegisterSchema = Yup.object().shape({
  name: Yup.string().required("Name is mandatory"),
  email: Yup.string().required("email is mandatory"),
  course: Yup.string().required("Course is mandatory"),
  phone: Yup.string()
    .required("Contact number is mandatory")
    .matches(/^[6789]\d{9}$/, "Please enter a valid mobile number"),
});

const Register = () => {
  const navigate = useNavigate();
  const [viewTerms, setViewTerms] = useState(false);

  const courses = [
    { label: "High School", value: "High School" },
    { label: "Diploma/Certificate", value: "Diploma/Certificate" },
    { label: "Bachelor's Degree", value: "Bachelor's Degree" },
    { label: "Master's Degree", value: "Master's Degree" },
    { label: "Doctorate/Ph.D.​", value: "Doctorate/Ph.D.​" },
    { label: "Other (Please Specify)", value: "Other (Please Specify)" },
  ];

  const onSubmit = async (values) => {
    formik.setSubmitting(true);
    const params = {
      name: values.name || "",
      phone: values.phone || "",
      email: values.email || "",
      course:
        values.course === "Other (Please Specify)"
          ? values.otherCourse
          : values.course || "",
    };

    service
      .post("/register", params)
      .then((res) => {
        const resData = res?.data;
        if (resData?.token) {
          navigate("/scratch-card", {
            state: {
              token: resData.token,
              resDetails: resData?.details,
            },
          });
        }
      })
      .catch((err) => {
        const error = err?.response?.data?.error;
        if (error) {
          formik.setFieldError("phone", error);
        }
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };
  const debouncedSubmit = useDebouncedCallback(onSubmit, 300);
  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      course: "",
      otherCourse: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: debouncedSubmit,
    enableReinitialize: true,
  });
  const backGround = {
    // background: `url(${Backround}) bottom`,
    backgroundRepeat: "no-repeat",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "5vh 2rem",
    backgroundSize: "100% 48%",
    alignItems: "center",
  };
  return (
    <div style={backGround} className="full-screen-div overflow-y-scroll p-12">
      <div className="flex flex-col w-full sm:w-1/2 items-center justify-between mb-4 rounded-3xl">
        <div className="pb-4 w-full align-middle">
          <p className="text-[#3fc6f0] text-[20px] text-center ">
            Invest in your future with us.
          </p>
        </div>
        <div className="pb-4 flex w-full flex-col ">
          <label className="text-base pb-[8px]">What is your name ?</label>
          <input
            value={formik.values.name}
            onBlur={() => formik.setFieldTouched("name")}
            onChange={(e) => formik.setFieldValue("name", e.target.value)}
            className={`h-[50px] bg-[#4d3e6a] text-white rounded-[8px] focus:outline-none focus:border focus:border-[#3fc6f0] border border-${
              formik.touched.name & formik.errors.name ? "[red]" : "[#4d3e6a]"
            } p-[19px]`}
            // className="border w-[298px] h-[50px] p-[19px] rounded placeholder-gray-50 text-white bg-gradient-to-r from-violet-500 to-blue-900"
            placeholder="Name"
            type="text"
          />
          {formik.touched.name && formik.errors.name && (
            <p className="text-[red] text-left italic">{formik.errors.name}</p>
          )}
        </div>
        <div className="pb-4 flex w-full flex-col">
          <label className="text-base pb-[8px]">How can we contact you ?</label>
          <input
            value={formik.values.phone}
            onBlur={() => formik.setFieldTouched("phone")}
            onChange={(e) => formik.setFieldValue("phone", e.target.value)}
            className={`h-[50px] bg-[#4d3e6a] rounded-[8px] border text-white focus:outline-none focus:border focus:border-[#3fc6f0] ${
              formik.touched.phone & formik.errors.phone
                ? "border-[red]"
                : "border-[#4d3e6a]"
            } p-[19px]`}
            // className="border w-[298px] h-[50px] p-[19px] rounded placeholder-gray-50 text-white bg-gradient-to-r from-violet-500 to-blue-900"
            placeholder="Phone"
            type="number"
          />
          {formik.touched.phone && formik.errors.phone && (
            <p className="text-[red] text-left italic">{formik.errors.phone}</p>
          )}
        </div>

        <div className="pb-4 flex w-full flex-col ">
          <label className="text-base pb-[8px]">What is your email id ?</label>
          <input
            value={formik.values.email}
            onBlur={() => formik.setFieldTouched("email")}
            onChange={(e) => formik.setFieldValue("email", e.target.value)}
            className={`h-[50px] bg-[#4d3e6a] rounded-[8px] text-white focus:outline-none focus:border focus:border-[#3fc6f0]  border border-${
              formik.touched.email & formik.errors.email ? "[red]" : "[#4d3e6a]"
            } p-[19px] `}
            // className="border w-[298px] h-[50px] p-[19px] rounded placeholder-gray-50 text-white bg-gradient-to-r from-violet-500 to-blue-900"
            placeholder="Enter email"
            type="text"
          />
          {formik.touched.email && formik.errors.email && (
            <p className="text-[red] text-left italic">{formik.errors.email}</p>
          )}
        </div>
        <div className="flex w-full flex-col">
          <label className="text-base pb-[8px]">Course Pursuing ?</label>
          <div class="custom-select">
            <select
              value={formik.values.course}
              onBlur={() => formik.setFieldTouched("course")}
              onChange={(e) => formik.setFieldValue("course", e.target.value)}
              className={`h-[50px] appearance-none text-white bg-[#4d3e6a] rounded-[8px] focus:outline-none focus:border focus:border-[#3fc6f0] border ${
                formik.touched.course && formik.errors.course
                  ? "border-[red]"
                  : "border-[#4d3e6a]"
              } px-[19px]`}
              placeholder="Role"
              type="text"
            >
              <option value="">Select Course</option>
              {courses.map((it) => (
                <option value={it.value}>{it.label}</option>
              ))}
            </select>
          </div>
          {formik.values.course === "Other (Please Specify)" && (
            <input
              value={formik.values.otherCourse}
              onBlur={() => formik.setFieldTouched("otherCourse")}
              onChange={(e) =>
                formik.setFieldValue("otherCourse", e.target.value)
              }
              className={`mt-2 h-[50px] bg-[#4d3e6a] rounded-[8px] text-white border border-${
                formik.touched.otherCourse & formik.errors.otherCourse
                  ? "[red]"
                  : "[#4d3e6a]"
              } p-[19px]`}
              // className="border w-[298px] h-[50px] p-[19px] rounded placeholder-gray-50 text-white bg-gradient-to-r from-violet-500 to-blue-900"
              placeholder="Enter other course"
              type="text"
            />
          )}
          {formik.touched.course && formik.errors.course && (
            <p className="text-[red] text-left italic">
              {formik.errors.course}
            </p>
          )}
        </div>
      </div>
      <div className="flex w-full sm:w-1/2 flex-col ">
        <div className="flex items-center w-full pb-4 justify-center relative">
          <button
            disabled={!!formik.isSubmitting}
            onClick={() => {
              formik.handleSubmit();
              // navigate("/scratch-card");
            }}
            className="bg-[#faa219] rounded-3xl text-[#1e1045]  justify-center px-6 py-3 text-center w-full h-[50px] flex items-center text-bold text-2xl"
          >
            <span>{formik.isSubmitting ? "Loading" : "Lets Go"}</span>
          </button>
        </div>

        <div className="p-2 rounded-[8px] mx-[-0.75rem] flex text-[#EEE8FA] font-normal font-['lato'] text-sm leading-5 z-10 bg-[#1d153a]">
          <p className="">*&nbsp;</p>
          <p className="">
            Terms and conditions apply.
            <button
              onClick={() => setViewTerms(true)}
              style={{ textDecoration: "underline", fontStyle: "italic" }}
            >
              View more
            </button>
          </p>
        </div>
      </div>
      {viewTerms && (
        <TermsAndCondition onCloseHandler={() => setViewTerms(false)} />
      )}
    </div>
  );
};

export default Register;
