import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./scratchCard1.css";
import scratchCard from "../assets/scratchCard.svg";
import headSet from "../assets/headSet.png";
import watch from "../assets/watch.png";
import foodCoupon from "../assets/foodCoupon.png";
import blnt from "../assets/blnt.png";
import CircleIcon from "../assets/CircleIcon";

const ScratchCard1 = ({ prize = "Next Time Bro", scratchCompletedHandler }) => {
  // const [count, setCount] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const canvasElement = document.getElementById("scratch");
    const canvasContext = canvasElement.getContext("2d");
    const initializeCanvas = () => {
      const gradient = canvasContext.createLinearGradient(0, 0, 135, 135);
      var background = new Image();
      background.src = scratchCard;
      background.onload = function () {
        canvasContext.drawImage(background, 0, 0, 234, 234);
      };
      // canvasContext.fillRect(0, 0, 0, 0);
    };

    let mouseX = 0;
    let mouseY = 0;
    let isDragging = false;

    const eventTypes = {
      mouse: {
        down: "mousedown",
        move: "mousemove",
        up: "mouseup",
      },
      touch: {
        down: "touchstart",
        move: "touchmove",
        up: "touchend",
      },
    };

    let deviceType = "";

    const checkIfTouchDevice = () => {
      try {
        document.createEvent("TouchEvent");
        deviceType = "touch";
        return true;
      } catch (e) {
        deviceType = "mouse";
        return false;
      }
    };

    const getMouseCoordinates = (event) => {
      mouseX =
        (!checkIfTouchDevice() ? event.pageX : event.touches[0].pageX) -
        canvasElement.getBoundingClientRect().left;
      mouseY =
        (!checkIfTouchDevice() ? event.pageY : event.touches[0].pageY) -
        canvasElement.getBoundingClientRect().top;
    };

    checkIfTouchDevice();

    canvasElement.addEventListener(eventTypes[deviceType].down, (event) => {
      isDragging = true;
      getMouseCoordinates(event);
      scratch(mouseX, mouseY);
    });

    canvasElement.addEventListener(eventTypes[deviceType].move, (event) => {
      if (!checkIfTouchDevice()) {
        event.preventDefault();
      }
      if (isDragging) {
        getMouseCoordinates(event);
        scratch(mouseX, mouseY);
      }
    });

    canvasElement.addEventListener(eventTypes[deviceType].up, () => {
      isDragging = false;
    });

    canvasElement.addEventListener("mouseleave", () => {
      isDragging = false;
    });

    const scratch = (x, y) => {
      canvasContext.globalCompositeOperation = "destination-out";
      canvasContext.beginPath();
      // canvasContext.arc(x, y, 12, 0, 10 * Math.PI);
      // if (count <= 250) {
      //   canvasContext.arc(15, 199.5, 1000, 0, 10 * Math.PI);
      // } else {
      canvasContext.arc(x, y, 48, 0, 2 * Math.PI);
      // }
      // canvasContext.arc(15, 199.5, 1000, 0, 10 * Math.PI);

      canvasContext.fill();

      // Check if the scratch card is more than 80% revealed
      const imageData = canvasContext.getImageData(
        0,
        0,
        canvasElement.width,
        canvasElement.height
      );
      const pixels = new Uint32Array(imageData.data.buffer);
      const transparentPixels = pixels.filter((pixel) => !pixel);
      const revealedPercentage =
        (transparentPixels.length / pixels.length) * 100;
      const handleMouseUpOrTouchEnd = () => {
        if (revealedPercentage >= 70) {
          canvasContext.globalCompositeOperation = "destination-out";
          canvasContext.fillRect(
            0,
            0,
            canvasElement.width,
            canvasElement.height
          );
          canvasElement.style.pointerEvents = "none";
          scratchCompletedHandler();
        }
      };
      canvasElement.addEventListener("mouseup", handleMouseUpOrTouchEnd);
      canvasElement.addEventListener("touchend", handleMouseUpOrTouchEnd);
    };

    initializeCanvas();
  }, [navigate, prize, scratchCompletedHandler]);

  const getPrizeImage = (prize) => {
    switch (prize) {
      case "20%":
        return watch;
      case "15%":
        return headSet;
      // case "Food Coupon":
      //   return foodCoupon;
      case "Next Time Bro":
        return blnt;

      default:
        return blnt;
    }
  };

  return (
    <div className="container">
      <div>
        <div
          style={{
            width: "25px",
            height: "49px",
            position: "absolute",
            left: "0",
            top: "0",
            transform: "translate(-11px, -177px",
          }}
          className="buble-icons-top"
        >
          <div className="">
            <CircleIcon fill="#510DBC" variant="sm" />
            <CircleIcon fill="#510DBC" />
            <CircleIcon variant="lg" stroke="#510DBC" />
          </div>
        </div>
        <div
          style={{
            width: "25px",
            height: "49px",
            position: "absolute",
            right: "0",
            bottom: "0",
            transform: "translate(-11px, 177px",
          }}
          className="buble-icons-bottom"
        >
          <div className="">
            <CircleIcon fill="#510DBC" variant="sm" />
            <CircleIcon fill="#510DBC" />
            <CircleIcon variant="lg" stroke="#510DBC" />
          </div>
        </div>
      </div>
      {/* {count} */}
      <div className="base1">
        <img
          className=" justify-start items-center inline-flex"
          src={getPrizeImage(prize)}
          draggable="false"
          style={{ width: "", height: "", borderRadius: "" }}
        />
      </div>
      <canvas
        id="scratch"
        width="234"
        height="234"
        style={{
          cursor: `${scratchCard}, auto`,
          // width: "500px",
        }}
      ></canvas>
    </div>
  );
};

export default ScratchCard1;
