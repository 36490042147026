import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import SplashScreen from "../component/splashScreen";
import Register from "../component/register";
import ScratchCard from "../component/scratchCard";
import CongratulationsScreen from "../component/CongratulationsScreen";
import BestOfLuck from "../component/BestOfLuck";

const Index = () => {
  return (
    <Routes>
      <Route path="/" element={<SplashScreen />} />
      <Route path="register" element={<Register />} />
      <Route path="scratch-card" element={<ScratchCard />} />
      <Route path="result" element={<CongratulationsScreen />} />
      <Route path="better-luck" element={<BestOfLuck />} />
      <Route index element={<SplashScreen />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default Index;
