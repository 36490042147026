import React, { useState, useMemo } from "react";
import debounce from "debounce";
import ScratchCard1 from "./scratchCard1";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import service from "../utils/service";
import Realistic from "./confetti";

const ScratchCard = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [prize, setPrize] = useState("");
  const [scratchDetails, setScratchDetails] = useState("");
  const [scratchCompleted, setscratchCompleted] = useState(false);

  const generateScratch = async () => {
    service
      .post(
        "/generate-scratch-card",
        {},
        {
          headers: {
            Authorization: state.token,
          },
        }
      )
      .then((res) => {
        const scratchCard = res?.data?.scratchCard;
        setScratchDetails(scratchCard);
        if (scratchCard?.prize) {
          setPrize(scratchCard.prize);
        } else {
          setPrize("");
        }
      })
      .catch()
      .finally();
  };
  useEffect(() => {
    if (!state?.token) {
      navigate("/");
    } else {
      generateScratch();
    }
  }, [state]);

  const navigatePage = (isPriced) => {
    if (isPriced) {
      navigate("/result", {
        state: { prize, scratchDetails: scratchDetails },
        replace: true,
      });
    }
    //  else {
    //   navigate("/better-luck", { replace: true });
    // }
  };

  const scratchCompletedHandler = () => {
    const isPriced = ["20%", "15%", "10%"].includes(prize);
    setscratchCompleted(isPriced);
    setTimeout(navigatePage, 3500, isPriced);
  };

  const backGround = useMemo(
    () => ({
      // background: `url(${Backround}) bottom`,
      backgroundRepeat: "no-repeat",
      // backgroundPosition: "center bottom",
      display: "flex",
      flexDirection: "column",
      padding: "3rem 2rem",
      backgroundSize: "100% 28%",
      alignItems: "center",
    }),
    []
  );
  return (
    <div style={backGround} className="full-screen-div">
      <div className="flex flex-col">
        <div className="w-[310px] flex flex-col items-center mb-2">
          <p className="text-[#3fc6f0] text-[20px] text-center">
            Great Job, {state?.resDetails?.name}
          </p>
          <h5 className="text-white">You’ve earned a scratch card!</h5>
        </div>
        <div className="flex flex-col items-center  justify-center">
          <div
            className=""
            // className="w-[323px] h-[323px]  bg-gradient-to-r from-violet-500 via-violet-600 to-indigo-700 rounded-full shadow border"
            style={{
              position: "relative",
              display: "flex",
              // width: "80vw",
              // height: "80vw",
              // maxHeight: "358px",
              // maxWidth: "358px",
              height: "90vw",
              width: "88vw",
              maxWidth: "334px",
              maxHeight: "334px",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              backgroundColor: "red",
              strokeColor: "black",
              background:
                "linear-gradient(90deg, #9163EA 10.99%, #7554DE 76.64%)",
              boxShadow: "rgba(145, 99, 234, .5) 0px 0px 0px 20px",
              margin: "6vh 0rem",
            }}
          >
            <ScratchCard1
              prize={prize}
              scratchCompletedHandler={debounce(scratchCompletedHandler, 500)}
            />
          </div>
        </div>
        <p className=" text-center text-white text-lg leading-[27px]">
          Scratch away to reveal an exclusive scholarship offer just for you!
        </p>
        {scratchCompleted && <Realistic />}
      </div>
    </div>
  );
};

export default ScratchCard;
// gradiant blue and vio 1
