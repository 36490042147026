import { useEffect } from "react";
import ProjectRouter from "./routes";

function App() {
  useEffect(() => {
    const updateDvh = () => {
      document.documentElement.style.setProperty(
        "--dvh",
        window.innerHeight + "px"
      );
    };

    window.addEventListener("resize", updateDvh);
    updateDvh();

    return () => {
      window.removeEventListener("resize", updateDvh);
    };
  }, []);
  return (
    <div className="full-screen-div">
      <ProjectRouter />
    </div>
  );
}

export default App;
