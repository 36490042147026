import axios from "axios";

const service = axios.create({
  baseURL: "https://api.happynewyear.freston.io",
  // baseURL: "http://localhost:6969",
  timeout: 60000,
});

export const setupInterceptors = (store) => {
  service.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("access_token");
      if (!config.headers.Accept) {
        config.headers.Accept = "application/json";
      }
      if (token) {
        // JWT token added to the header
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  service.interceptors.response.use(
    (response) => {
      //for success response, same response gets send to the method that called
      return response;
    },
    async (error) => {
      let originalRequest = error.config;
      // if (error?.response?.status === 401) {
      //   store.dispatch(logout() as any);
      // }
      // for errror response, if its unauthorized error(token expiry) and if the end point is not a public endpoint request to fetch new token will be send with refresh token
      // if (
      //   error?.response?.status === 401 &&
      //   !originalRequest._retry &&
      //   !publicEndpoints.includes(originalRequest.url)
      // ) {
      //   originalRequest._retry = true;
      //   return AuthService.refreshToken()
      //     .then((response) => {
      //       if (response.statusCode === 200) {
      //         //if new token is fetched original request is send again and the response will be given to the method that called it.
      //         return axios(originalRequest);
      //       } else {
      //         throw new Error(response.message || "");
      //       }
      //     })
      //     .catch((err) => {
      //       // if error response => user will be logged out
      //       store.dispatch(logout() as any);
      //       return Promise.reject(error.config ? error.response.data : error);
      //     });
      // } else {
      //for other cases error response will passed to the method
      return error?.response;
      // }
    }
  );
};

export default service;
